import React from "react";

const FaqIcon = () => {
  return (
    <svg
      width="111"
      height="115"
      viewBox="0 0 111 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_725_1249)">
        <path
          d="M68.9157 58.291C65.1549 56.1801 60.9005 55.1347 56.6013 55.265C52.8632 55.15 49.1404 55.7975 45.6551 57.1689C42.4248 58.4685 40.0596 60.2277 38.4264 62.5466C37.8722 63.3756 37.4929 64.3114 37.3123 65.2953C37.0949 66.4014 37.4909 67.2084 38.3994 67.5097C39.452 67.8584 40.1177 67.1739 40.6809 66.3826L40.8815 66.0998C41.5068 65.214 42.1534 64.2983 42.9687 63.6882C44.2261 62.7554 45.6 61.995 47.0545 61.4264C50.1686 60.2088 53.6132 59.8181 57.8946 60.1982C60.8597 60.4614 63.3638 61.1522 65.5498 62.3098C68.6741 63.964 70.6653 66.0631 71.6379 68.727C73.0148 72.5013 72.2553 75.5296 69.3804 77.7279C67.7399 78.9231 66.0247 80.0095 64.2455 80.9804L64.1623 81.0282C63.3025 81.522 62.3882 81.9634 61.5039 82.3903C60.8827 82.6903 60.2397 83.001 59.6169 83.3286C55.3383 85.5794 52.3965 88.5517 50.6236 92.415C50.1715 93.3564 49.8507 94.3563 49.6701 95.3867C49.5414 96.1745 49.5752 96.9806 49.7691 97.7546C49.9631 98.5284 50.3131 99.2534 50.7972 99.8842C51.279 100.529 51.775 100.667 52.1064 100.67H52.1161C52.3491 100.66 52.5767 100.596 52.7807 100.482C52.9847 100.368 53.1595 100.207 53.291 100.012C53.5467 99.6809 53.8066 99.3523 54.0676 99.0241C54.7549 98.1545 55.4654 97.2555 56.09 96.3128C58.0419 93.3636 60.6963 90.9558 64.4434 88.7349C66.7603 87.4039 69.0083 85.9536 71.1785 84.3898C72.7011 83.2653 74.0687 81.9407 75.2449 80.4508C77.7099 77.2884 78.5252 73.6783 77.668 69.7205C76.6054 64.8259 73.6608 60.9813 68.9157 58.291Z"
          fill="#316BCB"
        />
        <path
          d="M51.113 114.688C51.3786 114.728 51.6467 114.748 51.9152 114.748C53.2158 114.732 54.4779 114.3 55.5212 113.515C56.5645 112.73 57.3354 111.631 57.7235 110.375C57.9578 109.422 57.9723 108.427 57.7657 107.467C57.5591 106.507 57.137 105.608 56.532 104.84C56.0722 104.207 55.4673 103.697 54.7693 103.354C54.0712 103.011 53.301 102.845 52.5252 102.87C51.4943 102.873 51.3254 102.945 49.6302 103.948C48.4521 104.61 47.5315 105.657 47.0196 106.918C46.5077 108.179 46.4347 109.578 46.8129 110.887C47.4449 113.075 48.8921 114.354 51.113 114.688ZM51.8171 106.332C51.9103 106.458 52.0034 106.576 52.0935 106.692C52.3653 107 52.5882 107.349 52.7541 107.726C52.9445 108.254 52.9845 108.826 52.8697 109.376C52.7697 109.644 52.5722 109.864 52.3175 109.991C51.979 110.212 51.5735 110.304 51.1741 110.249C50.9688 110.193 50.3442 110.023 50.3868 108.786C50.4324 107.462 50.8508 106.734 51.8174 106.33L51.8171 106.332Z"
          fill="#316BCB"
        />
      </g>
      <path
        d="M23.7324 9.25807C24.254 10.6231 24.7693 11.9899 25.249 13.3724C25.5118 14.102 25.7673 14.8381 26.0786 15.5475C27.7436 19.3323 29.4553 23.0997 31.0279 26.925C31.9849 29.2845 32.9173 31.6539 33.8251 34.0333L35.1756 37.6061L36.4857 41.1937C36.7318 41.9265 36.9183 42.6778 37.0434 43.4401C37.1087 43.75 37.0987 44.0709 37.0143 44.3762C36.9299 44.6816 36.7734 44.9626 36.5578 45.1962C35.9749 45.8562 35.0487 45.7348 34.5057 44.8393C34.1668 44.2482 33.9039 43.6175 33.723 42.9617C33.282 41.4759 32.7789 40.0146 32.2137 38.5778C31.6538 37.1389 31.0345 35.723 30.3559 34.33C29.9821 33.5608 29.546 32.7786 29.3508 31.9669C28.7241 29.3745 27.552 26.9705 26.7182 24.4506C26.0971 22.578 25.3326 20.7498 24.6541 18.8942L22.8346 13.9321C22.2187 12.2817 21.6437 10.6145 21.0325 8.96119C20.8128 8.31052 20.6366 7.64623 20.5049 6.97263C20.3964 6.33254 20.3237 5.68699 20.2873 5.03893C20.2537 4.4658 20.6181 4.35263 21.0367 4.86103C21.6355 5.59172 22.1821 6.36296 22.6726 7.16911C22.8744 7.49994 23.0578 7.84389 23.2266 8.19531C23.3805 8.55286 23.535 8.91168 23.6886 9.26838L23.7324 9.25807Z"
        fill="#316BCB"
      />
      <path
        d="M66.1359 4.85605L65.9294 6.71221L65.6772 8.56572C65.5896 9.2214 65.5013 9.88037 65.4703 10.5403C65.4268 11.4207 65.3943 12.3016 65.3654 13.1826L65.2631 15.8236L65.0701 21.1041L64.8163 27.6051L64.5984 34.1082C64.5581 34.7663 64.4702 35.4207 64.3353 36.0663C64.291 36.3332 64.1844 36.5862 64.024 36.805C63.8635 37.0238 63.6537 37.2024 63.4113 37.3265C62.7498 37.6834 62.0543 37.3118 61.8877 36.4252C61.792 35.8467 61.7718 35.2584 61.8277 34.6748C62.0283 32.0198 62.0595 29.355 61.9211 26.6962C61.8376 25.961 61.8389 25.2189 61.9249 24.484C62.3229 22.2201 62.2457 19.9207 62.5058 17.6486C62.7003 15.9613 62.7734 14.2574 62.96 12.5669C63.3044 9.55334 63.6783 6.54542 64.1629 3.55157C64.3346 2.41301 64.695 1.31044 65.2296 0.28846C65.4601 -0.154367 65.8401 -0.082163 65.938 0.469382C66.0721 1.2604 66.1538 2.05927 66.1826 2.86086C66.1938 3.52381 66.1653 4.18684 66.0974 4.84645L66.1359 4.85605Z"
        fill="#316BCB"
      />
      <path
        d="M101.532 23.5875C100.837 24.4175 100.137 25.2448 99.4337 26.0695C99.0413 26.4913 98.6746 26.9359 98.3354 27.4009C97.4594 28.6581 96.6111 29.9349 95.7636 31.211C94.9145 32.486 94.0579 33.7544 93.1814 35.0081C92.1053 36.5544 91.0134 38.0889 89.9249 39.6259L86.6338 44.2188C86.2785 44.6757 85.8683 45.0879 85.4123 45.4463C84.9868 45.8066 84.3806 45.8146 83.7236 45.6291C82.7664 45.3589 82.1168 44.533 82.3598 43.7676C82.5302 43.2844 82.8 42.8416 83.1521 42.4671C84.6826 40.7208 86.0003 38.8019 87.0781 36.7501C87.3792 36.1821 87.6507 35.5601 88.1259 35.1372C89.6355 33.7822 90.6166 32.0321 91.9471 30.5466C92.9311 29.4405 93.8077 28.2456 94.7703 27.121C95.6285 26.1182 96.4943 25.1223 97.3677 24.1332L100.051 21.2176C100.746 20.4533 101.616 19.8647 102.588 19.5008C102.683 19.4582 102.79 19.445 102.893 19.4628C102.997 19.4806 103.093 19.5287 103.169 19.6008C103.244 19.673 103.297 19.766 103.319 19.8678C103.341 19.9696 103.332 20.0756 103.293 20.1723C103.06 20.841 102.788 21.4954 102.477 22.1321C102.186 22.6292 101.858 23.1043 101.496 23.5535L101.532 23.5875Z"
        fill="#316BCB"
      />
      <path
        d="M109.402 54.9125C109.137 55.0354 108.877 55.1678 108.605 55.2748L107.786 55.5934C107.493 55.6986 107.21 55.8281 106.94 55.9803C106.233 56.4119 105.54 56.8716 104.827 57.29C104.118 57.7169 103.407 58.1397 102.682 58.5355C101.79 59.0241 100.89 59.4971 99.9753 59.94L97.2304 61.2693C96.9331 61.3882 96.6159 61.4511 96.2953 61.4546C95.982 61.4765 95.631 61.1946 95.2878 60.7688C94.7878 60.1483 94.5694 59.3297 94.8507 58.9724C95.031 58.76 95.2746 58.6099 95.5466 58.5437C96.7465 58.189 97.8635 57.602 98.8331 56.8166C99.1059 56.5995 99.3659 56.3255 99.7152 56.2818C100.825 56.1311 101.69 55.4748 102.709 55.1377C103.469 54.8922 104.159 54.4974 104.91 54.2304C106.246 53.7484 107.566 53.2366 108.923 52.797C109.482 52.6066 110.086 52.5931 110.653 52.7583C110.722 52.7798 110.786 52.816 110.839 52.8645C110.893 52.9129 110.935 52.9723 110.963 53.0386C110.991 53.1049 111.004 53.1764 111.001 53.2482C110.998 53.32 110.979 53.3903 110.946 53.4542C110.829 53.6183 110.705 53.779 110.577 53.9341C110.454 54.0889 110.318 54.233 110.17 54.3649C109.925 54.5586 109.663 54.7296 109.386 54.8758L109.402 54.9125Z"
        fill="#316BCB"
      />
      <path
        d="M2.9373 48.3825C3.54333 48.7282 4.14465 49.0794 4.74124 49.4363C5.05243 49.6331 5.3753 49.811 5.70809 49.9693C6.61107 50.352 7.51145 50.7454 8.40532 51.1561C9.29729 51.5704 10.1726 52.0182 11.0312 52.4995C12.0809 53.1025 13.1131 53.7372 14.1167 54.4144C15.1205 55.0912 16.1051 55.7948 17.0706 56.5252C17.359 56.765 17.6045 57.0514 17.7968 57.3723C17.9989 57.6728 17.9307 58.1164 17.7174 58.6012C17.406 59.307 16.7467 59.8096 16.2593 59.6726C15.9587 59.5741 15.689 59.4002 15.4762 59.1679C14.4753 58.1586 13.282 57.3567 11.9656 56.8089C11.6021 56.6524 11.1966 56.536 10.9342 56.2472C10.0958 55.3179 8.93654 54.8712 7.94949 54.1619C7.58045 53.9002 7.19738 53.6584 6.80195 53.4377C6.41141 53.2086 6.01378 52.9915 5.62547 52.7576C4.23986 51.9252 2.84031 51.1011 1.40136 50.3408C0.806407 50.002 0.336896 49.4828 0.0619516 48.8596C-0.0931938 48.5721 0.0480962 48.2626 0.429299 48.1152L0.817938 48.0099C0.877521 47.9906 0.940357 47.983 1.00288 47.9877L1.17868 47.991C1.41057 47.9972 1.64187 48.0174 1.87132 48.0512C2.05535 48.0802 2.23575 48.1285 2.40949 48.1953C2.57903 48.2693 2.74581 48.3509 2.91615 48.4219L2.9373 48.3825Z"
        fill="#316BCB"
      />
      <defs>
        <clipPath id="clip0_725_1249">
          <rect
            width="41"
            height="60"
            fill="white"
            transform="translate(37 55)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FaqIcon;
