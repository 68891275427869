"use client"
import {
  BrandContainer,
  ContentContainer,
  Layout,
  Stack,
} from "../../../../../components/molecules";
import UserContactForm from "../../../contact/UserContactForm";
import ArrowRightIcon from "../../../../../assets/svg/ArrowRightIcon";
import { useDictionary } from "../../../../../context/dictionaryProvider";
import { useParams } from "next/navigation";

const Enquiry = ({ space, id }) => {
  const dict = useDictionary();
  const params = useParams()
  return (
    <Layout className={`prerequisites ${space ? space : "mb-40 mt-8"}`}>
      <ContentContainer maxWidth="1200px">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-7">
          <div>
            <Stack style={{ flexDirection: "column" }} className="">
              <h3 className="mb-16 text-2xl lg:text-4xl font-semibold text-black">
                {dict?.form?.letsForm?.title}
              </h3>
            </Stack>
            <BrandContainer className={`${params.lang === "ar" ? "items-start" : "items-center"}`}>
              <ul className="flex flex-col">
                {dict?.form?.letsForm?.learMoreList?.map((overview, index) => (
                  <li
                    className="flex text-[16px] text-black mb-8 gap-3"
                    key={index}
                  >
                    <span>
                      <ArrowRightIcon className="stroke-[#FFDA73]" />
                    </span>
                    <span>{overview}</span>
                  </li>
                ))}
              </ul>
            </BrandContainer>
          </div>
          <UserContactForm
            title={dict?.form?.letsForm?.letsChat}
            id="lets-chat"
            showformShape
            btnText={dict?.contactUs}
            btnStyle={{
              backgroundColor: "#ABE69E",
              color: "#000",
              padding: "16px 16px",
            }}
          />
        </div>
      </ContentContainer>
    </Layout>
  );
};

export default Enquiry;
