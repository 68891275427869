"use client";
import React from "react";
import { useDictionary } from "../../context/dictionaryProvider";

const ArrowRightIcon = ({ className, props }) => {
  const dict = useDictionary();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        dict?.dir === "rtl" && "transform rotate-180"
      } ${className}`}
      {...props}
    >
      <g id="arrow-narrow-right">
        <path
          id="Icon"
          d="M4 12H20M20 12L14 6M20 12L14 18"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default ArrowRightIcon;
