"use client";
// import React, { useState } from "react";
// import Markdown from "react-markdown";
// import { H2Heading, H4Heading, Heading, SubHeading, TP } from ".";
// import rehypeRaw from "rehype-raw";
// import remarkBreaks from "remark-breaks";
// import { useDictionary } from "../../context/dictionaryProvider";

// const Editor = ({
//   content = "",
//   className,
//   readMore = false,
//   initialLength = 350,
// }) => {
//   const dict = useDictionary();
//   const [isExpanded, setIsExpanded] = useState(!readMore);
//   const shouldShowReadMore = readMore && content.length > initialLength;

//   const handleToggle = () => {
//     setIsExpanded(!isExpanded);
//   };

//   const displayedContent = isExpanded
//     ? content
//     : `${content.substring(0, initialLength)}...`;

//   return (
//     <div>
//       <Markdown
//         className={className}
//         rehypePlugins={[rehypeRaw]}
//         remarkPlugins={[remarkBreaks]}
//         components={{
//           h1: ({ children }) => <Heading className="mb-2">{children}</Heading>,
//           h2: H2Heading,
//           h4: H4Heading,
//           h3: ({ children }) => (
//             <SubHeading className="mb-2">{children}</SubHeading>
//           ),
//           p: ({ children }) => <TP className="my-3">{children}</TP>,
//         }}
//       >
//         {displayedContent}
//       </Markdown>
//       {shouldShowReadMore && (
//         <button onClick={handleToggle} className="text-blue-500 underline mt-2">
//           {isExpanded ? dict?.viewLess : dict?.viewMore}
//         </button>
//       )}
//     </div>
//   );
// };

// export default Editor;

import React, { useState } from "react";
import Markdown from "react-markdown";
import { H2Heading, H4Heading, Heading, SubHeading, TP } from ".";
import rehypeRaw from "rehype-raw";
import remarkBreaks from "remark-breaks";
import { useDictionary } from "../../context/dictionaryProvider";

const Editor = ({ content = "", className, readMore = false, faqs }) => {
  const dict = useDictionary();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <div
        className={`${className} ${
          isExpanded ? "max-h-full" : "overflow-y-auto"
        } overflow-hidden relative mb-5`}
      >
        <Markdown
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[remarkBreaks]}
          components={{
            h1: ({ children }) => (
              <Heading className="mb-3">{children}</Heading>
            ),
            h2: ({ children }) => (
              <H2Heading className="mb-3">{children}</H2Heading>
            ),
            h3: ({ children }) => (
              <SubHeading className="mb-3">{children}</SubHeading>
            ),
            h4: ({ children }) => (
              <H4Heading className="mb-3">{children}</H4Heading>
            ),
            p: ({ children }) => (
              <TP className={`${faqs ? faqs : "my-3"}`}>{children}</TP>
            ),
            a: ({ children, ...rest }) => (
              <a
                className="underline underline-offset-2 text-blue-600 hover:text-blue-800"
                {...rest}
              >
                {children}
              </a>
            ),
            ul: ({ children }) => (
              <ul className="mt-4 text-[#11181c] list-disc list-outside dark:text-gray-400 ml-5 marker:text-black marker:text-xl mb-3">
                {children}
              </ul>
            ),
            ol: ({ children }) => (
              <ol className="mt-4 text-[#11181c] list-decimal list-outside dark:text-gray-400 ml-5 marker:text-black marker:text-md mb-3">
                {children}
              </ol>
            ),
          }}
        >
          {content}
        </Markdown>
      </div>
      {readMore && (
        <button onClick={handleToggle} className="text-blue-500 underline mt-2">
          {isExpanded ? dict?.viewLess : dict?.viewMore}
        </button>
      )}
    </div>
  );
};

export default Editor;
